@import url(https://fonts.googleapis.com/css2?family=Press+Start+2P&family=Teko:wght@300;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Press+Start+2P&family=Teko:wght@300;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Press+Start+2P&family=Teko:wght@300;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Press+Start+2P&family=Teko:wght@300;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Press+Start+2P&family=Teko:wght@300;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Press+Start+2P&family=Teko:wght@300;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Press+Start+2P&family=Teko:wght@300;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Press+Start+2P&family=Teko:wght@300;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Press+Start+2P&family=Teko:wght@300;500&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.main {
  font-size: 25px;
  text-align: center;
  font-family: 'Teko', sans-serif;
  font-weight: 300;
}

.screen {
  background-color: rgb(27, 27, 27);
  font-size: 30px;
}


/* Menu: */

.light {
  background-color: white;
  font-family: 'Teko', sans-serif;

}

.dark {
  background-color: rgb(27, 27, 27);
  font-family: 'Teko', sans-serif;
  color: white;  
}

.lightTitle {
  background-color: white;
  font-family: 'Teko', sans-serif;
  font-size: 70px;

}

.darkTitle {
  background-color: rgb(27, 27, 27);
  font-family: 'Teko', sans-serif;
  color: white;  
  font-size: 70px;
}

@media only screen and (max-width: 600px) {
  .lightTitle {
    font-size: 50px;
    color: black;
    font-family: 'Teko', sans-serif;
    height: 100px;
    padding-right: 20px;
  }
  
  .darkTitle {
    font-size: 50px;
    color: white;
    font-family: 'Teko', sans-serif;
    height: 100px;
    padding-right: 20px;
  }
}

.titleImage {
  font-size: 20px;
  color: white;
  font-family: 'Teko', sans-serif;
  height: 100px;
  padding-right: 20px;
}

.titleSub {
  font-size: 20px;
  color: white;
  font-family: 'Teko', sans-serif;
}

.iconSize {
  height: 100px;
}


/* Start Button: */

.buttonStart {
  background-color: rgb(27, 27, 27) ;
  border-style: solid;
  border-color: rgb(27, 27, 27);
  border-radius: 10px;
}

.blink {
  font-size: 20px;
  color: white;
  font-family: 'Press Start 2P', cursive;
  -webkit-animation: blink 1s steps(1, end) infinite;
          animation: blink 1s steps(1, end) infinite;
  padding: 5px;
}

@-webkit-keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}


/* Error 404 Button: */

.button404 {
  background-color: white ;
  border-style: solid;
  border-color: white;
  border-radius: 10px;
}

.text404 {
  font-size: 20px;
  color: black;
  font-family: 'Press Start 2P', cursive;
  padding: 5px;
}

.right {
  float: right;
  padding-right: 70px;
}


/* Footer */

.footer {
  background-color: white;
  text-align: center;
  font-family: 'Teko', sans-serif;
  font-weight: 300;
  font-size: 20px;
}

hr.solid {
  border-top: 3px solid #bbb;
}

.main {
  font-size: 25px;
  text-align: center;
  font-family: 'Teko', sans-serif;
  font-weight: 300;
}

.break {
  visibility: hidden;
}

/* Section 01 (S1): */

.s1 {
  background-color: #EEEE00;
  /* background-color: #95dbc1; */
  text-align: center;
  font-family: 'Teko', sans-serif;
  font-weight: 300;
  font-size: 20px;
}

/* .s1Block {
  display: inline-block;
}

.s1TitleSub {
  font-size: 40px;
  font-family: 'Teko', sans-serif;
  font-weight: 300;
}

.s1TitleSubDark {
  font-size: 20px;
  font-family: 'Teko', sans-serif;
  font-weight: 300;
  color: white;
}

@media only screen and (max-width: 600px) {
  .s1TitleSub {
    color: black;
    font-size: 30px;
    font-family: 'Teko', sans-serif;
    font-weight: 300;
  }
}

.s1Text {
  font-size:25px;
  font-family: 'Teko', sans-serif;
  font-weight: 300;
}

.s1video {
  width: 300px;
  height: auto;
  border-style: solid;
  border-color: black;
  border-right-width: 100px;
  border-left-width: 100px;
  border-radius: 10px;
} */
/* VGInfo: */

.info {
  font-size: 25px;
  font-family: 'Teko', sans-serif;
  font-weight: 300;
  background-color: #404040;
  color: white;
}

.infoTitleVG {
  text-align: left;
}

@media only screen and (max-width: 600px) {
  .infoTitleVG {
    text-align: center;
  }
}

.infoImage {
  width: 150px;
  height: 150px;
  padding: 5px;
}
/* VGInfo: */

.info {
  font-size: 25px;
  text-align: center;
  font-family: 'Teko', sans-serif;
  font-weight: 300;
  background-color: #404040;
  color: white;
}

.infoImage {
  width: 150px;
  height: 150px;
  padding: 5px;
}
/* ColInfo: */

.info {
  font-size: 25px;
  font-family: 'Teko', sans-serif;
  font-weight: 300;
  background-color: #404040;
  color: white;
}

.infoTitleCol {
  text-align: right;
}

@media only screen and (max-width: 600px) {
  .infoTitleCol {
    text-align: center;
  }
}

.infoImage {
  width: 150px;
  height: 150px;
  padding: 5px;
}
/* Section 02 (S2): */

.s2 {
  /* background-color: #EEEE00; */
  /* background-color: #95dbc1; */
  background-color: #08f9a0;
  text-align: center;
  font-family: 'Teko', sans-serif;
  font-weight: 300;
  font-size: 20px;
}

.s2TitleSub {
  color: black;
  font-size: 25px;
  font-family: 'Teko', sans-serif;
  font-weight: 300;
}

.s2TitleSubDark {
  color: white;
  font-size: 25px;
  font-family: 'Teko', sans-serif;
  font-weight: 300;
}

/* Section 03 (S3): */

.s3 {
  background-color: white;
  text-align: center;
  font-family: 'Teko', sans-serif;
  font-weight: 300;
  font-size: 20px;

}

.s3Dark {
  background-color: rgb(27, 27, 27);
  text-align: center;
  font-family: 'Teko', sans-serif;
  color: white;  
}

.s3Text {
  color: black;
  font-size: 25px;
  font-family: 'Teko', sans-serif;
  font-weight: 300;
}

.s3TextDark {
  color: white;
  font-size: 25px;
  font-family: 'Teko', sans-serif;
  font-weight: 300;
}

.card {
  margin: 0 auto; /* Added */
  float: none; /* Added */
  margin-bottom: 10px; /* Added */
}

/* Contact 03 (S3): */

.contact {
  background-color: white;
  text-align: center;
  font-family: 'Teko', sans-serif;
}

.contactDark {
  background-color: rgb(27, 27, 27);
  text-align: center;
  font-family: 'Teko', sans-serif;
  color: white;  
}

.contactTitleSub {
  color: black;
  font-size: 20px;
  font-family: 'Teko', sans-serif;
  font-weight: 300;
}

.contactTitleSubDark {
  color: white;
  font-size: 25px;
  font-family: 'Teko', sans-serif;
  font-weight: 300;
}

@media only screen and (max-width: 600px) {
  .s3TitleSub {
    color: black;
    font-size: 30px;
    font-family: 'Teko', sans-serif;
    font-weight: 300;
  }
}

/* Portolio */
/* .portfolio {
  background-color: darkkhaki;
  background-color: rgb(98, 107, 125);
} */

.d-block {
  /* height: 500px; */
}

.card-img-top {
  width: 100%;
  height: 400px;
  object-fit: cover;
}

.main2 {
  text-align: center;
  font-family: 'Teko', sans-serif;
  font-weight: 300;
  color: white;
}

