@import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&family=Teko:wght@300;500&display=swap');

/* ColInfo: */

.info {
  font-size: 25px;
  font-family: 'Teko', sans-serif;
  font-weight: 300;
  background-color: #404040;
  color: white;
}

.infoTitleCol {
  text-align: right;
}

@media only screen and (max-width: 600px) {
  .infoTitleCol {
    text-align: center;
  }
}

.infoImage {
  width: 150px;
  height: 150px;
  padding: 5px;
}