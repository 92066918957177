@import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&family=Teko:wght@300;500&display=swap');

/* VGInfo: */

.info {
  font-size: 25px;
  text-align: center;
  font-family: 'Teko', sans-serif;
  font-weight: 300;
  background-color: #404040;
  color: white;
}

.infoImage {
  width: 150px;
  height: 150px;
  padding: 5px;
}