@import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&family=Teko:wght@300;500&display=swap');

/* Portolio */
/* .portfolio {
  background-color: darkkhaki;
  background-color: rgb(98, 107, 125);
} */

.d-block {
  /* height: 500px; */
}

.card-img-top {
  width: 100%;
  height: 400px;
  object-fit: cover;
}

.main2 {
  text-align: center;
  font-family: 'Teko', sans-serif;
  font-weight: 300;
  color: white;
}
