@import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&family=Teko:wght@300;500&display=swap');

.main {
  font-size: 25px;
  text-align: center;
  font-family: 'Teko', sans-serif;
  font-weight: 300;
}

.break {
  visibility: hidden;
}

/* Section 01 (S1): */

.s1 {
  background-color: #EEEE00;
  /* background-color: #95dbc1; */
  text-align: center;
  font-family: 'Teko', sans-serif;
  font-weight: 300;
  font-size: 20px;
}

/* .s1Block {
  display: inline-block;
}

.s1TitleSub {
  font-size: 40px;
  font-family: 'Teko', sans-serif;
  font-weight: 300;
}

.s1TitleSubDark {
  font-size: 20px;
  font-family: 'Teko', sans-serif;
  font-weight: 300;
  color: white;
}

@media only screen and (max-width: 600px) {
  .s1TitleSub {
    color: black;
    font-size: 30px;
    font-family: 'Teko', sans-serif;
    font-weight: 300;
  }
}

.s1Text {
  font-size:25px;
  font-family: 'Teko', sans-serif;
  font-weight: 300;
}

.s1video {
  width: 300px;
  height: auto;
  border-style: solid;
  border-color: black;
  border-right-width: 100px;
  border-left-width: 100px;
  border-radius: 10px;
} */