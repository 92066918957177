@import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&family=Teko:wght@300;500&display=swap');

/* Section 03 (S3): */

.s3 {
  background-color: white;
  text-align: center;
  font-family: 'Teko', sans-serif;
  font-weight: 300;
  font-size: 20px;

}

.s3Dark {
  background-color: rgb(27, 27, 27);
  text-align: center;
  font-family: 'Teko', sans-serif;
  color: white;  
}

.s3Text {
  color: black;
  font-size: 25px;
  font-family: 'Teko', sans-serif;
  font-weight: 300;
}

.s3TextDark {
  color: white;
  font-size: 25px;
  font-family: 'Teko', sans-serif;
  font-weight: 300;
}

.card {
  margin: 0 auto; /* Added */
  float: none; /* Added */
  margin-bottom: 10px; /* Added */
}
