@import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&family=Teko:wght@300;500&display=swap');

/* Contact 03 (S3): */

.contact {
  background-color: white;
  text-align: center;
  font-family: 'Teko', sans-serif;
}

.contactDark {
  background-color: rgb(27, 27, 27);
  text-align: center;
  font-family: 'Teko', sans-serif;
  color: white;  
}

.contactTitleSub {
  color: black;
  font-size: 20px;
  font-family: 'Teko', sans-serif;
  font-weight: 300;
}

.contactTitleSubDark {
  color: white;
  font-size: 25px;
  font-family: 'Teko', sans-serif;
  font-weight: 300;
}

@media only screen and (max-width: 600px) {
  .s3TitleSub {
    color: black;
    font-size: 30px;
    font-family: 'Teko', sans-serif;
    font-weight: 300;
  }
}
