@import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&family=Teko:wght@300;500&display=swap');

.main {
  font-size: 25px;
  text-align: center;
  font-family: 'Teko', sans-serif;
  font-weight: 300;
}

.screen {
  background-color: rgb(27, 27, 27);
  font-size: 30px;
}


/* Menu: */

.light {
  background-color: white;
  font-family: 'Teko', sans-serif;

}

.dark {
  background-color: rgb(27, 27, 27);
  font-family: 'Teko', sans-serif;
  color: white;  
}

.lightTitle {
  background-color: white;
  font-family: 'Teko', sans-serif;
  font-size: 70px;

}

.darkTitle {
  background-color: rgb(27, 27, 27);
  font-family: 'Teko', sans-serif;
  color: white;  
  font-size: 70px;
}

@media only screen and (max-width: 600px) {
  .lightTitle {
    font-size: 50px;
    color: black;
    font-family: 'Teko', sans-serif;
    height: 100px;
    padding-right: 20px;
  }
  
  .darkTitle {
    font-size: 50px;
    color: white;
    font-family: 'Teko', sans-serif;
    height: 100px;
    padding-right: 20px;
  }
}

.titleImage {
  font-size: 20px;
  color: white;
  font-family: 'Teko', sans-serif;
  height: 100px;
  padding-right: 20px;
}

.titleSub {
  font-size: 20px;
  color: white;
  font-family: 'Teko', sans-serif;
}

.iconSize {
  height: 100px;
}


/* Start Button: */

.buttonStart {
  background-color: rgb(27, 27, 27) ;
  border-style: solid;
  border-color: rgb(27, 27, 27);
  border-radius: 10px;
}

.blink {
  font-size: 20px;
  color: white;
  font-family: 'Press Start 2P', cursive;
  animation: blink 1s steps(1, end) infinite;
  padding: 5px;
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}


/* Error 404 Button: */

.button404 {
  background-color: white ;
  border-style: solid;
  border-color: white;
  border-radius: 10px;
}

.text404 {
  font-size: 20px;
  color: black;
  font-family: 'Press Start 2P', cursive;
  padding: 5px;
}

.right {
  float: right;
  padding-right: 70px;
}


/* Footer */

.footer {
  background-color: white;
  text-align: center;
  font-family: 'Teko', sans-serif;
  font-weight: 300;
  font-size: 20px;
}

hr.solid {
  border-top: 3px solid #bbb;
}
